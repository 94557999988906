<template>
  <div>
    <div class="card p-2">
      <div>
        <h3 class="font-weight-bolder text-center">
          รายการล็อตเตอรี่ที่ลูกค้าซื้อแล้ว
        </h3>
      </div>
      <div class="row">
        <div class="col-2" />
        <div class="col-8">
          <div>
            <label for="example-datepicker">เลือกรอบวันที่</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="round"
              class="mb-1"
            />
          </div>
          <!-- <div>
            <label for="lotto">กรอกเลขล็อตเตอรี่</label>
            <input
              id="lotto"
              v-model="lotto"
              type="text"
              class="form-control"
              placeholder="กรอกเลขล็อตเตอรี่"
            >
          </div> -->

          <div class="mt-2">
            <button
              class="btn btn-gradient-primary btn-block"
              @click="onSubmit()"
            >
              ค้นหาข้อมูล
            </button>
          </div>
        </div>
        <div class="col-2" />
      </div>
    </div>

    <div class="card">
      <!-- <div class="d-flex mt-2 ml-2">
        <b-button
          variant="gradient-success"
          class="rounded mr-1"
        >
          พร้อมจำหน่าย
        </b-button>

        <b-button
          variant="gradient-warning"
          class="rounded"
        >
          จำหน่ายแล้ว
        </b-button>
      </div> -->
      <b-table
        hover
        show-empty
        responsive
        :items="items"
        :fields="fields"
        class="mt-1"
      >
        <template #cell(id)="data">
          <router-link :to="{ name: '' }">
            {{ data.item.id }}
          </router-link>
        </template>

        <template #cell(depfirst_amount)="data">
          {{ data.item.depfirst_amount ? data.item.depfirst_amount : "0.00" }}
        </template>
        <template #cell(created_at)="data">
          ( {{ Time(data.item.created_at) }} ) {{ data.item.created_at }}
        </template>

        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(status)="data">
          <span
            v-if="data.item.status === 0"
            class="badge badge-warning badge-circle"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-25"
            />
            <span>พร้อมจำหน่าย</span>
          </span>

          <span
            v-else
            class="badge badge-success"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>จำหน่ายแล้ว</span>
          </span>
        </template>

        <template #cell(action)>
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
            >
              <i class="fa fa-edit" />
            </b-button>
            <!-- <b-button
              variant="info"
              class="mr-1"
              size="sm"
            >
              เพื่อนที่แนะนำ
            </b-button> -->
          </div>
        </template>
      </b-table>
      <b-card-body
        class="d-flex justify-content-between flex-wrap pt-0 pl-1 pb-1"
      >
        <!-- page length -->
        <b-form-group
          label=""
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <p>จำหน่ายแล้ว {{ totalRows }} ใบ</p>
          <!-- {{ totalRows ? totalRows: 0 }} -->
          <!-- <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          /> -->
        </b-form-group>

        <!-- pagination -->
        <div class="pr-1 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
  </div>
</template>

<script>
import {
  BFormDatepicker, BTable, BCardBody, BPagination, BFormGroup, BButton,
  // BFormSelect
} from 'bootstrap-vue'

export default {
  name: 'Reward',
  components: {
    BFormDatepicker,
    BTable,
    BCardBody,
    BPagination,
    BFormGroup,
    // BFormSelect,
    BButton,
  },
  data() {
    return {
      lotto: '',
      list: [],
      round: null,
      fields: [
        { key: 'lottonumber', label: 'เลขลสาก' },
        { key: 'status', label: 'สถานะ' },
        { key: 'roundth', label: 'งวดประจำวันที่' },
        { key: 'set_no', label: 'เล่มที่' },
        { key: 'agent_buy', label: 'ตัวเทน' },
        { key: 'member_buy', label: 'ลูกค้าซื้อ' },

        // { key: 'action', label: 'Action' },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      show: false,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    onSubmit() {
      if (!this.round) {
        this.$swal('กรุณาเลือกรอบที่ต้องการค้นหา')
        return
      }

      this.getList()
    },
    getList() {
      const params = {
        page: this.currentPage,
        round: this.round,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/getsoldall', params)
        .then(response => {
          this.show = false
          this.items = response.data.lottery
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.table {
    border-spacing: 0 15px;
    border-collapse: separate;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    scrollbar-width: none;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.table tbody tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
    border-radius: 5px 0 0 5px;
}
.table tbody tr td {
    background: #fff;
}
.table thead tr th, .table thead tr td, .table tbody tr th, .table tbody tr td {
    vertical-align: middle;
    border: none;
}
</style>
